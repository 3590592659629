import styles from "./background.module.scss";
import getColor from "../../../styles/abstracts/_colors.scss";
import * as React from "react";

export default function Background({ x = 0, y = 0, size = 1 }) {
  let viewString = `${x} ${y} 600 585`;
  let scaleString = `scale(${size})`;

  const { leaf, leafShade, wood, woodShade } = getColor;

  React.useEffect(() => console.log("leaf: ", leaf));
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewString}
      className={styles.background}
      transform={scaleString}
      // width="100%"
    >
      <title>tree</title>
      <defs>
        <radialGradient id="leafGradient" cx="0.29" cy="0.29" r="0.76">
          <stop offset="0%" stopColor={leaf} />
          <stop offset="100%" stopColor={leafShade} />
        </radialGradient>
        <radialGradient id="woodGradient" cx="0.29" cy="0.29" r="0.76">
          <stop offset="0%" stopColor={wood} />
          <stop offset="76%" stopColor={wood} />
          <stop offset="100%" stopColor={woodShade} />
        </radialGradient>
      </defs>

      <g className={styles.leaf}>
        <path d="M44.23,204,48.09,200Z" />
        <path d="M43.09,204.85l1.13-.82Z" />
        <path d="M549.42,195.47c-.39-1.56-.77-3.12-1.16-4.68q-1.53-4.11-3.08-8.22c-2.7-8.24-8.87-13.64-15.35-18.79-5.21-3.79-12.52-4.15-18.67,1.06L509,168.7a9.71,9.71,0,0,0-.89,10.19c.16.42.32.84.49,1.26s.29.57.43.86l.62.94,4.55,7h.34L516,190h0l9,5,0,0,2.09,1,3.8,1.09,4.17,1-.06,0c3.82.67,7.63,1.44,11.47,2C550.51,200.55,551.13,198.73,549.42,195.47Z" />
        <path d="M48.12,199.91l.71-1Z" />
        <path d="M535.17,241.88c.46.47.92.93,1.37,1.4L535,242.8A3.21,3.21,0,0,1,535.17,241.88Z" />
        <path d="M15,186.22l-.8.7Z" />
        <path d="M219.15,44.89l.88,1Z" />
        <path d="M218.36,43.91l.72.88Z" />
        <path d="M101.64,571.34c-.48-5.88-1.15-11.75-1.74-17.62l-2.64-5.07-1-3.39-1.54.39L96,556c.31,5.72.61,11.43,1,18.16-1.46-2.45-2.12-4.45-3.48-5.69-3.64-3.33-4.64-9.92-11.38-9.49,3.35,6.81,6.25,13.89,10.24,20.3,1.78,2.87,5.73,4.39,8.69,6.52.08-3,.12-6.09.25-9.13A45.45,45.45,0,0,0,101.64,571.34ZM82,558.76l.08.2h0l0,0Z" />
        <path d="M241.3,549.24l-1.27-.76-4.66,5.46q-4.73,6.78-9.44,13.56c-1.26,1.8-2.55,3.58-3.83,5.37q.33-8.58.67-17.15V541l-1.58-.08-1,5c-3.54,10.09-3.5,20.52-3,31-.06,1.07-.11,2.14-.17,3.21l-.83,5.65c1.69-.85,4-1.25,4.95-2.62,4.56-6.85,8.73-14,13-21l.83-1.27.21-.2-.13-.26Z" />
        <path d="M27.93,238.54l-.46-.65,1-.49c.09.26.31.58.23.77S28.18,238.42,27.93,238.54Z" />
        <path d="M38.22,146.08c.9-5.69,1.46-11.47,2.82-17,1.14-4.69,3-5.39,6.77-2.76,8.78,6.17,16.85,13.2,20.59,23.65a25,25,0,0,1,1.32,11.65c-.86,5.67-4.49,10.48-10.09,11.9-5.23,1.33-10.56-.44-14.51-4.58A20.35,20.35,0,0,1,39,154.66c0-2.82,0-5.65,0-8.47Z" />
        <path d="M96.2,235.82c6.52,5.21,1,14.77-5,15.91-3.61.68-7.78.92-11.16-.23-5.56-1.89-10.73-5-15.94-7.81-.69-.37-1.2-2.85-.86-3.09,7.38-5.46,15.26-9.69,24.89-8.84l7,3v0Z" />
        <path d="M481.77,145.8c-3.38-1.77-7.41-2.32-11.15-3.41l-.2.61c-3.29.33-6.61.47-9.86,1.06s-4.26,2.81-2.39,5.37c4.28,5.85,9.39,10.66,16.86,12.17,4.18.84,8.7-1.06,10.13-4.3C487.2,152.68,486.23,148.13,481.77,145.8Z" />
        <path d="M557.94,141.22c-6.17-2.43-12.28-5.48-18.71-6.71-8.11-1.56-14.21,3-15.93,10.21-.46,7.32,6,11.29,14.05,10.68,8.6-.65,15.37-4.86,20.86-11.33C561.29,142.84,558.92,141.61,557.94,141.22Z" />
        <path d="M590.43,272.62c-5.73-2.46-11.62-4.9-17.68-6.11C566,265.16,559.59,269.43,558,275c-.12,1.71-.78,3.58-.29,5.09,1.94,5.92,8.68,8.8,15.42,6.9,7.06-.42,12.23-4.75,17.6-8.58C593.8,276.22,593.85,274.1,590.43,272.62Z" />
        <path d="M355.79,82.81c-3.43-1.19-7.62-2.19-10.92-1.28-5.7,1.58-11.11,4.46-16.36,7.32-2,1.09-3.8,3.57,0,5.49,2.63,1.33,5.12,2.92,7.68,4.4,5.47,2.12,10.89,4.54,16.95,2.28,5.31-1.09,8.41-4.08,8.89-8.53A9.27,9.27,0,0,0,355.79,82.81Z" />
        <path d="M577.2,320.3c-2.33-11.7-6.79-22.23-16.86-29.44-4.26-3.06-9.12-5-14.49-3.67-11.15,2.72-14.39,15.08-6.35,24.48,6.33,7.39,14.68,11.12,24.22,12.4l15.48,2.4C578.3,323.67,577.75,322,577.2,320.3Z" />
        <path d="M45,182.33c-1.87-1.16-3.63-3.09-5.61-3.36-9.12-1.22-17.45.86-24.39,7.25l-.8.7-.13.11L2,199c-3,2.46-2.65,4.1,1.15,5.1,4.27,1.12,8.5,2.39,12.75,3.59,6,2.28,12.12,3.15,18.3.57l1.86-.58,7-2.8.09,0,1.13-.82h0L48.09,200l0,0,.71-1a1.6,1.6,0,0,0,.35-.87A26.28,26.28,0,0,0,51,193.19C51.75,188.8,48.85,183.75,45,182.33Z" />
        <path d="M420.2,146.69c-2.16-2.51-5-4.46-7.52-6.66l-5.76,10.56c-.87,6-2.24,11.9-2.45,17.88A13,13,0,0,0,412,181l1.91,1.06c4.63,2.48,8.61,1.41,12.12-2.21,1.39-2.82,3.68-5.54,4-8.48C431.11,161.54,426.28,153.73,420.2,146.69Z" />
        <path d="M505,341.22c-.83-4.37-4-9.29-8.91-10-4.53-.67-8,1.32-10.12,5.4-1.89,6.2-2,12.19,2,17.7,2.58,3.31,5.12,6.66,7.76,9.92,1.86,2.3,3.83,2.12,5.7-.07l3.48-12.07C505,348.46,505.63,344.72,505,341.22Z" />
        <path d="M152.41,146.79c.06-4.12-3-5.76-6.07-6.86-5.8-2-10.73.24-15.19,3.89l-9.2,10-4,5.74,4.79,1.06c6.19.07,12.45.77,18.55.07C149.27,159.78,153.42,154,152.41,146.79Z" />
        <path d="M185.56,112.83c-8.61,1-15.94,4.21-20.58,12-.33,1-.65,2-1,3h0c0,1,0,2.07,0,3.1l-.05,0q1.52,3,3,6h0l2.9,1.8c5.5,3,9.56-.18,13.13-3.55,5.54-5.21,8.62-11.88,9.8-19.41C192.54,111.1,191.46,110.66,185.56,112.83Z" />
        <path d="M245.81,22.91c-2-7.08-4-14.18-6.18-21.22-.73-2.38-2.23-2-3.6-.33L224.81,11.8c-2.87,5.28-6.37,10.34-8.43,15.92-1.85,5-2.4,10.62,1.57,15.39a1.14,1.14,0,0,0,.41.8l.72.88.07.1c.29.34.59.68.88,1l1,1c6.6,6.22,14.6,5,20.71-3.07a1.07,1.07,0,0,0,.43-.78l2.65-5.21c.11-.65.21-1.3.31-2q.41-4,.79-8Z" />
        <path d="M516,114.89l-3.91-7q-2-3-3.92-6.06l0,.06-1-1.71c-3.32-4.61-3.29-4.59-6.86-.07-4.64,5.89-8.44,12.2-9.86,19.7-1.95,6.75-2.63,13.5.51,20.08,2.58,5.38,9.07,9.16,14.08,8.44,5.38-.76,12.73-6.06,12.86-10.41C518.11,130.22,519.71,122.29,516,114.89Z" />
        <path d="M518.78,281.05c-.77-8.58-2.9-16.54-10.11-22.19l-1.56-1h0l-2.88-1.12-.13.11-4-.8-4.21,1.78a1.1,1.1,0,0,0-.82.45c-5.88,6-4.89,12.66,2.78,18.77l.08.07L503.9,281l.1.06,2,.88.07,0,2,.86a1.17,1.17,0,0,0,.77.21l4.37,1.65C519,286.18,519,286.18,518.78,281.05Z" />
        <path d="M200.37,29c-.72-.76-1.45-1.52-2.17-2.29l-1-.88,0,0-2-1,0,0c-5-1.32-9.9-2.81-14.92-3.89-1.94-.42-3.87.21-2.17,3,1.52,8.24,4.31,15.84,10.93,21.48,6.15,5.24,13.31,4.55,17.17-1.64C208.94,37,206.6,31,200.37,29Z" />
        <path d="M378,97.45v0Zm39.14-27.31a118.21,118.21,0,0,0-11.87.76c-8.63.74-16.48,3.57-22.86,9.52-4.13,3.84-6.88,8.49-5.27,14.56.29.81.57,1.63.85,2.45l1,2.51h0c2.47,1.68,4.73,4.11,7.45,4.9,9.12,2.65,16.43-.75,22.24-7.9,7-5.23,9.13-13.41,12.48-20.8C423,72.23,421.53,70.21,417.15,70.14Z" />
        <path d="M470.15,260.86l-.45-3.05-.61-.75-.09-.22-.81-1.07v-.09c-2.27-4.82-6.66-6.48-11.35-7.9-3.36-1-6.31.84-9.34.89-9.88.16-17.07,5.8-24.64,10.82-2.71,1.17-3,2.81-.8,4.72A46.84,46.84,0,0,0,426,267v0l2,1.19,10.17,5.58L443,275h0c5.69,1.85,11.38,2.76,17.06,0l6.21-3.57.92-1.47v0C470.26,267.64,470.74,264.43,470.15,260.86Z" />
        <path d="M347.13,131.83v0l-1-1-.06,0-.9-.72-1-.35-.85-.6a12.93,12.93,0,0,0-3.14-1.27c-9.68-1.31-17.79,2.28-25.12,8.23-2.29,2.06-1,3.57,1,4.54,4.71,2.39,9.38,5.06,14.37,6.66a21.55,21.55,0,0,0,10.73.47C349.09,146,353.4,136.75,347.13,131.83Z" />
        <path d="M482.94,76.2c-10.76.68-21.54,1.89-30.64,8.37-3.8,2.72-7.91,5.68-8.32,11.15,0,.41-.06.81-.07,1.21-.21,2.82-.71,5.7-.14,8.35,1.43,6.55,7.19,8,12.67,8.4,6.39.41,12.26-1.54,15.75-7.7l-.05,0c.35-.35.69-.7,1-1v0l3-3.06h0c.33-.35.66-.69,1-1h0l1-2,0,0,.79-1c2.55-5.85,5.17-11.66,7.6-17.56C487.88,77.07,485,76.07,482.94,76.2Z" />
        <path d="M483.81,231.58a34.78,34.78,0,0,0-14.5-22.8l-1.22-.95h0c-2.42-3.27-4.14-3-5.14,1l0,0-2.91,9c-2.11,6-1.52,12.12.64,17.88,1,2.7,3.56,4.81,5.41,7.2,3.22,1.72,6.3,4.62,10.09,1.1C481.16,242.2,484.7,236.54,483.81,231.58Z" />
        <path d="M66.18,202.41c-.44-.56-.87-1.13-1.31-1.7-4.13-4.29-7.73-3.19-10.3,3.17.16,2,.33,4,.49,5.93l0,.1c.61,1.44,1.21,2.88,1.82,4.31a.73.73,0,0,0,.29.69L58,216h0l1.82,2.17h0c1.13,1,2.35,2.89,3.35,2.78a4.7,4.7,0,0,0,3.14-3.11,128,128,0,0,0,1.61-13.53C68,203.7,66.79,203,66.18,202.41Z" />
        <path d="M233.17,94.47a.41.41,0,0,0-.18-.59l-.83-1.26c-.18-.29-.36-.58-.53-.88a.52.52,0,0,0-.54-.55l0-.26-.25-.06a3.37,3.37,0,0,1-.71-.7l-.08-.24h-.25l-1.65-1.1h0c-3.37-2.52-6.74-1.92-10.11,0h0l-2,1,0,0-1.15,1L213,92.72c-.67,1-1.34,2-2,3.06v0l-.8,1.07a4.12,4.12,0,0,0,.86-.19,4.12,4.12,0,0,1-.86.19q-1.12,3.72-2.26,7.44c-2.89,6-1.27,12.16-.56,18.25.1.82,1.77,1.46,2.72,2.18,3-1.3,6.24-2.19,8.89-4,6.92-4.68,13.36-9.91,14.88-18.92Z" />
        <path d="M356.52,26.09c-1.64-1.27-3.66-1.89-4.83.82-5,11.54-7.58,23.5-4,35.83,1.58,5.48,4.54,10.8,11.73,10.87l.47.12.07.28.29,0c10.07.93,14.49-6.57,15.93-14.69.25-1.43.46-2.86.68-4.3C373.78,42.8,366.26,33.64,356.52,26.09Z" />
        <path d="M216.34,71a16.58,16.58,0,0,0-10.7-12.15,30.72,30.72,0,0,0-19-.73c-6.28,1.79-12.4,4.18-18.59,6.31l-1.06,1.32,0,0c-2.88.21-2.27,1.71-.93,3,2.24,2.18,4.67,4.15,7,6.2h0l1,1,11,7L187,84l0,0,6,2.47,10.13.58,8-4h0c1-1,2-2,3-3h0l.92-2C215.54,75.59,216.84,73.09,216.34,71Z" />
        <path d="M274.88,60.08c-1.3-1.09-3.13-1.55-4.73-2.29v0l-1.06-.74a1.21,1.21,0,0,0-.84-.29L263.09,55l-2-.09L252,54.71a1,1,0,0,0-.93.18L246,57.83h0l-1,1h0c-3.58,6.26-2.88,9.41,3,13.56l4.06,1.43,1.53.23c8.11,2.24,14.33-1.86,20.52-6.1l0,0,4.51-3.18C277.41,63.18,276.38,61.35,274.88,60.08Z" />
        <path d="M382,248.4c-5.93-4.47-11.93-8.85-17.9-13.27l-7.84-1.41a1.53,1.53,0,0,0-.28-.78l-.84-.85c-5.6-.41-11.29-1.42-16.11,2.73h0l-1.32.93L335,238.7c-3,3.88-3.07,8-1,12.26v0l1,2q1.92,2.06,3.85,4.1l5.89,3,2.36.71,17.07-.21,1-.65h0c5.41-1.94,10.91-3.7,16.21-5.91C384.22,252.81,384.8,250.53,382,248.4Z" />
        <path d="M102.85,167.5c-8.69-1.72-17-.89-24.86,3.44-5.87,2.46-7.46,8.07-4,13.93l0,.07,1,.93.09.07,1.82,1.1c3.85,3.27,8.13,2.45,12.13.79,7.21-3,12-8.68,16.06-15.14C107,169.64,106.13,168.15,102.85,167.5Z" />
        <path d="M198.75,174.07c-1.35-6.36-5.71-10.17-12.31-11.35-7.6-1.37-14.24.84-20.48,4.93l-1,1.2,0-.08-7,5h0l-1,1,0,0-2,2h.07l-1,1,0,0c-.29.3-.57.61-.86.92L150,182.15c-3,3.67-2.35,4.58,4.05,5.83l0-.08a5.46,5.46,0,0,0,.79.57c7,4.12,14.89,4.54,22.56,4.22a32.66,32.66,0,0,0,14.37-4.39C196.73,185.4,200.08,180.29,198.75,174.07Z" />
        <path d="M27.13,227.86c-6.11-5.43-13.86-6.16-21.44-7.18-.6-.08-2.32,2.36-2.16,3.42,1.34,9,3.83,17.75,8.52,25.71C18.16,260.16,28.28,264,38,259.59c2-2.71,5-5.15,5.82-8.17C46.29,242,37.16,229.53,27.13,227.86Z" />
        <path d="M309.24,95.84c-1.52-.76-3.38-.85-5.09-1.24l-2-.82h0c-.5-.59-.89-1.5-1.52-1.72-6.35-2.24-12.76-4.26-19.65-3l-8.87,1.07c-9.24,3.55-13.48,10.4-11.35,18.31.82,4.44,3.72,7,7.74,8.5,6.53,4.07,13,1.2,19.49-.14,9-3.09,15.36-9.86,22-16.24C311.92,98.66,311.36,96.89,309.24,95.84Zm-27,20.17c0-.5,0-1,0-1.51,0,.5,0,1,0,1.51Z" />
        <path d="M157.09,106.85l-4-7L152,98.28l-1.08-1.41-.83-1v0l-1.91-2.09v.07l-2.07-2.07.07.08-1.08-1.07h.1l-2.06-2v0l-1-1v0l-1-1-.06,0-2-.89c-3.57-3.87-6.31-3.55-7.71,1.41s-2.09,10.37-3.08,15.57q.75,7.56,1.52,15.11,1.59,3.47,3.17,7l-.06,0a1,1,0,0,0,.3.83c6.76,9.11,17.92,9,23.8-.07C161.17,119.36,159.3,113.16,157.09,106.85Z" />
        <path d="M534.94,217.13c.06-.2-1.23-.77-1.9-1.16l-2.21.21h-.48l-10.29.73h0c-9-.25-16.09,3.65-21.36,10.66-3.84,5.12-3.47,9.54.34,14.43l1,.89.09.06c.65.33,1.3.66,1.94,1h0c6.24,4.06,10.85,3.52,18-2.12q4-4.47,8.06-9l.16-.22-.13-.25,1-1.45q2-4.28,4-8.56h0C533.75,220.66,534.47,218.93,534.94,217.13Z" />
        <path d="M561.76,223.82c-7.73,2.06-15,5.23-20.9,10.85q-1.5,1.52-3,3l0,0h0l-.89,1.13c-.59,1-1.19,2-1.79,3.06.46.47.92.93,1.37,1.4L535,242.8c-.68.65-1.62,1.18-2,2-2.64,5.82-.62,13.63,4.29,17s12.93,2.66,17.76-1.87h0l1-1h0l1.18-1c8.28-8,9.79-18.49,10.72-29.14C568.45,224.08,566.52,222.55,561.76,223.82Zm-23.74,14,0,0Zm17,22,.06.06Z" />
      </g>

      <g className={styles.wood}>
        <path d="M251.55,102C246,87.24,239.36,73.86,228.24,62.8l-3-3.06-.1.09-1-1h0l-1-1,0,0-1-1-5.84-4.51-.8,1c.57.71,1.11,1.46,1.7,2.15,19.9,23.08,31,49.41,28.81,80.31-.49,6.86-2.34,13.66-4,20.37-.71,2.79-4,5.55-3.56,7.79.37,1.82,4.64,2.8,7.13,4.24.69.4,1.17,1.5,1.81,1.55,1.15.09,3.09,0,3.39-.67,2-4.33,4.77-8.7,5.21-13.26C257.75,137.66,258.24,119.81,251.55,102Z" />
        <path d="M508.93,158.46c-5.86,1.94-11.12,4.72-16.48,7.31-11.17,5.38-22.39,10.67-33.59,16-20.36,6.89-41,10.2-62.16,4a253,253,0,0,1-26.2-8.88c-9.38-3.87-9.21-4.29-15.05,4.33-.37.55-.73,1.11-1.07,1.67-2.57,4.25-2.35,5.37,2.29,6.76,7.26,2.17,14.58,4.22,22,5.88,24.73,5.57,49.37,6.16,73.44-3.09,6.87-2.64,13.31-6.44,19.94-9.71L485.94,175l1.2-1.05.1.13,2.93-2-.07-.05,1.53-1Z" />
        <path d="M265.15,193.8c-2.41-.86-5-1.36-7.17-2.64q-8.51-5-16.64-10.69c-2.27-1.57-4.08-3.81-6.1-5.74Z" />
        <path d="M489.33,244.93a18.29,18.29,0,0,0-2.81,4c-7.19,13.24-13.82,26.82-21.68,39.66-9.34,15.24-21.76,27.62-37.94,35.94-14.44,7.43-30,10.12-45.89,11.55-4.11-.11-8.25,0-12.33-.37-7.91-.78-15.78-1.87-23.67-2.83h-.4c-6.51-2.36-6.53-2.37-9.57,3-1.46,2.56-2.84,5.16-4.26,7.75l.23.23-1.15,1-1.89,3q-3.06,3.88-6.13,7.78c-2.8,3.55-2.45,4.45,2.05,5.15a16.56,16.56,0,0,0,3,.16c14.59-.31,29.23-.13,43.78-1.1A128.27,128.27,0,0,0,421,345.55c18.41-9.47,32.49-23.82,43.28-41.44l1.86-3.05.79-1.14.24-.07,0-.25a.61.61,0,0,0,.37-.44l.62-1.22,1.87-3.05a.41.41,0,0,0,.19-.61l8.8-17.4A157,157,0,0,0,489.33,244.93Z" />
        <path d="M429.17,121.86l-13.23,2,11.67-2.32,1.47.25Z" />
        <path d="M165,168.77l-7,5Z" />
        <path d="M78.1,268.72l9.9-2Z" />
        <path d="M95.13,234.79l-7-3Z" />
        <path d="M36.05,207.69l7-2.8Z" />
        <path d="M164,130.91l3,6Z" />
        <path d="M373.06,125.92l-.86-1.11c-6.7-15-2.53-29.34,2.47-43.84-7.32,7.74-12.59,16.41-14.42,27,.61,7,.47,14.12,2,20.88,1.74,7.54,4.56,8,11.12,3.56l.05-.14,1.59-4.05Zm2,2.25-1.59,4.05,3.64-1.78Z" />
        <path d="M273.16,199.77c-2.39.4-4-.21-4-3Z" />
        <path d="M242.15,439q1.44-2.06,2.89-4.09Q243.58,436.94,242.15,439Z" />
        <path d="M228.24,62.8l-3-3.06Z" />
        <path d="M276.13,202.84l-2.88-3.1Z" />
        <path d="M515.16,313l3.73-2.16Z" />
        <path d="M167,137l2.9,1.8Z" />
        <path d="M468.1,297.94l1.87-3.05Z" />
        <path d="M487.24,174.07l2.93-2Z" />
        <path d="M157,174.82l-2,2Z" />
        <path d="M281.34,206.34l-2.16-.73Z" />
        <path d="M310.26,270.9c0,1.32.07,2.64.1,4C310.33,273.54,310.29,272.22,310.26,270.9Z" />
        <path d="M165,124.84c-.33,1-.65,2-1,3C164.33,126.85,164.65,125.85,165,124.84Z" />
        <path d="M54.09,264.85l-2.11-.2c.17-.23.45-.7.5-.67.55.26,1.06.6,1.58.91Z" />
        <path d="M164,127.85v0Z" />
        <path d="M162.1,218.77l3.4-.81Z" />
        <path d="M412.06,129.85l2.08-.92Z" />
        <path d="M235.14,174.81l-2-1.08Z" />
        <path d="M75.11,185.94l1.82,1.1Z" />
        <path d="M520,309.62l1.24-1.55Z" />
        <path d="M278.18,204.74l-.79.44-.24-1.4Z" />
        <path d="M283.14,207.81l-1.86-1.2Z" />
        <path d="M183.09,213l2.54-1Z" />
        <path d="M214.13,80c.31-.69.62-1.37.92-2Z" />
        <path d="M485.94,175l1.2-1.05Z" />
        <path d="M158,173.82l-1,1Z" />
        <path d="M233.17,173.84l-1-.89Z" />
        <path d="M490.1,172l1.53-1Z" />
        <path d="M166,167.65l-1,1.2Z" />
        <path d="M100,251.82l-1,1Z" />
        <path d="M99,252.82l-1,1Z" />
        <path d="M277.16,203.86l-1.06-1Z" />
        <path d="M98,253.82l-.8,1Z" />
        <path d="M410,130.86l1.56-.91Z" />

        <path d="M429.08,121.78l-1.47-.25Z" />
        <path d="M325,260.87l-.85-1.51Z" />
        <path d="M325.84,262.82l-.7-1.15Z" />
        <path d="M478.1,99l.79-1Z" />
        <path d="M266.51,194.81l-1.41-1Z" />
        <path d="M440,119.25l-10.78,2.61-13.23,2c-7.29,2-14.75,3.52-21.84,6.07-16.77,6-32.49,14-45.3,26.81-3.15,3-6.47,5.74-9.42,8.88-12.72,13.54-23.68,28.2-28.4,46.62-.33,1.3-.62,3.18,0,4.07,4.21,5.63,8.69,11.06,13.69,17.32,1.05-3.32,1.77-5.49,2.42-7.68,5.5-18.54,12.84-36.23,24.12-52.08,7.72-10.86,16.73-20.43,28-27.74l13.94-7.55.93-.65v0l2-1v0l2-1,0,0,11.22-5,.36.06.3-.19,1.56-.91.26.12.2-.22,2.08-.92,26.11-8.55Z" />
        <path d="M219.26,185.59c-6.45-4.85-6.66-4.85-13,.34-21.25,17.49-45.46,27.23-73.15,26.69-8.35-.16-16.65-2.46-25-3.78l-11-4L64.59,187a43,43,0,0,0,7.46,8c11.88,9.93,24.77,18.08,40,21.91a.87.87,0,0,0,.69.24l1.28.69c10,3.46,20.3,3.59,30.63,3.06,5.2-.26,10.37-1.25,15.55-1.91l1.89-.17,3.4-.81.27.15.25-.19,17.11-5,0,0,2.54-1a18.64,18.64,0,0,0,2.81-.71c12.92-5.49,24.49-13.16,35.8-22C222.45,187.92,220.84,186.78,219.26,185.59Z" />
        <path d="M325.84,262.82l-.7-1.15a.72.72,0,0,0-.17-.8c-.29-.5-.57-1-.85-1.51l.14-.22-.17-.2c-6.74-15.47-17.08-28.35-29-40.11v0c-.33-.35-.65-.7-1-1l-7.62-7-2.33-2.06-.05.08-1-1h0l-1.86-1.2.06-.27-2.16-.73-1-.87-.79.44c-.08-.44-.15-.88-.23-1.32l-1.06-1,0,0-2.88-3.1-.09,0c-2.39.4-4-.21-4-3l-.05.11-1-1-.09-.06-.88-.74a.44.44,0,0,0-.63-.3l-1.41-1h0c-2.41-.86-5-1.36-7.17-2.64q-8.51-5-16.64-10.69c-2.27-1.57-4.08-3.81-6.1-5.74l-.1.08-2-1.08,0,.11-1-.89a9.15,9.15,0,0,0-1.58-1.16q-28-13.23-56.11-26.37a15.11,15.11,0,0,0-3.93-.68c10.36,6.15,21,11.33,31.22,17.33,14.85,8.74,29.8,17.44,43.87,27.35,11.76,8.28,22.33,18.27,33.42,27.49,1.31,1.48,2.59,3,3.93,4.43,13.24,14.25,24.45,29.61,27.32,49.56,0,1.32.07,2.64.1,4q-1,10-2.08,20a134.57,134.57,0,0,1-16.45,31.91L277,343.86c-10.41,9.28-20.51,18.95-31.3,27.75-9.51,7.75-19.8,14.55-29.74,21.76l-.93.63-1.1.82c-10.27,6.39-20.61,12.69-30.81,19.19A181.34,181.34,0,0,0,121,479.92c-12.7,22.91-18.94,47.81-21.12,73.8.59,5.87,1.26,11.74,1.74,17.62a45.45,45.45,0,0,1-.38,5.31c-.13,3-.17,6.09-.25,9.13l31.08.11,60-.1,24.16,0,.83-5.65c-.68-1.07-1.73-2.07-2-3.25-2.12-9.64-4.66-19.24-6-29a122.41,122.41,0,0,1,11.06-70.18c6.35-13.37,14.63-25.82,22-38.68h0q1.44-2.06,2.89-4.09c1.49-1.74,3-3.49,4.48-5.21,15.07-17,30.28-33.88,45.17-51,6.49-7.47,12.34-15.5,18.49-23.27a34.78,34.78,0,0,0,2.74-3.47c4.56-7.6,8.94-15.14,11.91-23.71,2.66-7.69,5.35-15.07,5.13-23.27C334.55,290.23,331.32,276.34,325.84,262.82Zm-19.45,53.91a1.13,1.13,0,0,1-.27.13l.36-.37C306.45,316.57,306.45,316.69,306.39,316.73Zm-29.24-113v.08h0Z" />
        <path d="M534.71,275.64q-7.92,13.56-15.85,27.13l-4.91,5-1.18,1a.83.83,0,0,0-.71.37l-1,.76a48.79,48.79,0,0,1-31.76,5.22c-9.61-1.65-9.68-1.4-16.11,5.61a14.23,14.23,0,0,0-1.57,2.82,10.9,10.9,0,0,0,3,1.43A130.83,130.83,0,0,0,479,326.45c9.88.23,19.59-1.15,28.27-6.2,3-1.75,5.3-4.77,7.92-7.21l3.73-2.16,1.12-1.26,1.24-1.55,0,0c.29-.35.58-.71.86-1.07l0,0,3.86-5,.06-.17,2.09-3.74,0-.05.71-1.08a1.06,1.06,0,0,0,.31-.78c.24-.4.47-.81.7-1.21q1.56-4,3.1-8,1.25-5.61,2.48-11.21Z" />
        <path d="M467.11,299.6a.61.61,0,0,0,.37-.44C467.51,299.42,467.39,299.57,467.11,299.6Z" />
        <path d="M466.12,301.06l.79-1.14Z" />
        <path d="M225.14,59.83l-1-1Z" />
        <path d="M521.27,308.05c.29-.35.58-.71.86-1.07C521.85,307.34,521.56,307.7,521.27,308.05Z" />
        <path d="M514,307.74l-1.18,1Z" />
        <path d="M224.14,58.83l-1-1Z" />
        <path d="M512.06,309.09l-1,.76Z" />
        <path d="M223.14,57.85l-1-1Z" />
        <path d="M331,343.8l-1.15,1Z" />
        <path d="M96.2,235.82l-1.06-1Z" />
        <path d="M215.06,394l-1.1.82Z" />
        <path d="M268,195.85l-.88-.74Z" />
        <path d="M54.06,264.89h.1l-.07,0Zm70.05-38.09c-1.11-.31-2.29-.35-3.42-.6-3.22-.71-5.09.58-6.78,3.43-4.44,7.5-9.27,14.78-13.94,22.14l0,0-1,1,0,0-1,1,0,0-.8,1a52.3,52.3,0,0,0-4.47,3c-8,6.46-17.26,8.77-27.39,8-3.74-.28-7.47-.59-11.21-.88,7.25,5.83,15.46,5.65,23.94,3.82l9.9-2c15.06-4.54,26.22-14.18,35.08-26.82l1.55-2,.48-.89c.29-.39.58-.78.88-1.16l1.19-1.92c.69-1.82,1.39-3.64,2.08-5.45C127.54,227.94,125.85,227.3,124.11,226.8Z" />
        <path d="M269.14,196.86l-1-1Z" />
        <path d="M331.06,348.88l-.22.42.24-.4Z" />
        <path d="M306.07,316.9l.41-.41c0,.08,0,.2-.09.24a1.17,1.17,0,0,1-.34.15Z" />
      </g>
    </svg>
  );
}
